import { graphql, useStaticQuery } from "gatsby"

interface SiteMetaData {
  endpoint: {
    contact: string
    lead: string
    newsletter: string
    signup: string
  }
  social: {
    twitter: string
  }
  siteUrl: string
}
interface Data {
  site: {
    siteMetadata: SiteMetaData
  }
}

export const useSiteMetadata = (): SiteMetaData => {
  const { site } = useStaticQuery<Data>(
    graphql`
      query {
        site {
          siteMetadata {
            endpoint {
              contact
              lead
              newsletter
              signup
            }
            social {
              twitter
            }
            siteUrl
          }
        }
      }
    `,
  )

  return site.siteMetadata
}
