import { Logo, PrimaryButton } from "@einride/ui"
import styled from "@emotion/styled"
import { Slider } from "components/ui/Header/components/Slider/Slider"
import { Link } from "components/ui/Link/Link"
import { cvars } from "lib/theming/breakpoint"
import { ThemeProvider } from "lib/theming/ThemeProvider/ThemeProvider"
import { useState } from "react"
import { NavLink } from "./components/NavLink/NavLink"
import { useHeaderContent } from "./hooks/useHeaderContent"

const StyledHeader = styled.header`
  background: ${({ theme }) => theme.colors.background.primary};
  height: ${cvars.menuHeight};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${cvars.sectionFrameSpacing};
`

const StyledLogo = styled(Logo)`
  @media ${({ theme }) => theme.mediaQueries.md} {
    height: ${({ theme }) => 5 * theme.spacer}px;
  }
`

const LogoText = styled.span`
  display: block;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
`

const OpenButton = styled.button`
  width: 32px;
  height: 32px;
  background: ${({ theme }) => theme.colors.background.secondary};
  color: ${({ theme }) => theme.colors.content.primary};
  border-radius: 50%;
  cursor: pointer;
  display: grid;
  place-items: center;
  font-size: ${({ theme }) => theme.fontSizes.md};

  @media ${({ theme }) => theme.mediaQueries.md} {
    width: 40px;
    height: 40px;
  }

  @media ${({ theme }) => theme.mediaQueries.lg} {
    display: none;
  }
`

const StyledNav = styled.nav`
  display: none;

  @media ${({ theme }) => theme.mediaQueries.lg} {
    display: flex;
    align-items: center;
    height: ${cvars.menuHeight};

    p:not(:last-child) {
      margin-right: ${({ theme }) => 5 * theme.spacer}px;
    }
  }
`

interface HeaderProps {
  hideMenu?: boolean
}

export const Header = ({ hideMenu }: HeaderProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)
  const { primaryLinks, secondaryLinks, theme } = useHeaderContent()

  return (
    <ThemeProvider colorMode={theme}>
      <StyledHeader>
        <Link to="https://www.einride.tech/" shortenLink={false}>
          <StyledLogo />
          <LogoText>Einride home</LogoText>
        </Link>
        <OpenButton onClick={() => setIsOpen(true)}>+</OpenButton>
        {!hideMenu ? (
          <StyledNav>
            {primaryLinks.map((link) => (
              <NavLink key={link.id} to={link.url}>
                {link.text}
              </NavLink>
            ))}
            {secondaryLinks &&
              secondaryLinks.map((link) => (
                <Link to={link.url} key={link.id}>
                  <PrimaryButton>{link.text}</PrimaryButton>
                </Link>
              ))}
          </StyledNav>
        ) : undefined}
      </StyledHeader>
      <ThemeProvider colorMode={theme === "light" ? "dark" : "light"}>
        <Slider isOpen={isOpen} setIsOpen={setIsOpen} />
      </ThemeProvider>
    </ThemeProvider>
  )
}
