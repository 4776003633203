import { Icon, Logo, VerticalSpacing } from "@einride/ui"
import styled from "@emotion/styled"
import { Link } from "components/ui/Link/Link"
import { cvars } from "lib/theming/breakpoint"
import { Paragraph, Title1 } from "lib/ui/Typography/Typography"
import { Dispatch, SetStateAction, useEffect } from "react"
import { useHeaderContent } from "../../hooks/useHeaderContent"

const SLIDER_WRAPPER_Z_INDEX = 1000

const Wrapper = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${({ theme }) => theme.colors.background.primary};
  color: ${({ theme }) => theme.colors.content.primary};
  padding: ${cvars.sectionFrameSpacing};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  z-index: ${SLIDER_WRAPPER_Z_INDEX};
  display: flex;
  flex-flow: column;
  justify-content: space-between;
`

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledLogo = styled(Logo)`
  @media ${({ theme }) => theme.mediaQueries.md} {
    height: ${({ theme }) => 5 * theme.spacer}px;
  }
`

const CloseButton = styled.button`
  width: 32px;
  height: 32px;
  background: ${({ theme }) => theme.colors.background.secondary};
  color: ${({ theme }) => theme.colors.content.primary};
  border-radius: 50%;
  cursor: pointer;
  display: grid;
  place-items: center;
  font-size: ${({ theme }) => theme.fontSizes.md};

  @media ${({ theme }) => theme.mediaQueries.md} {
    width: 40px;
    height: 40px;
  }
`

const StyledTitle1 = styled(Title1)`
  margin-bottom: ${({ theme }) => theme.spacer}px;
`

const PrimaryLinks = styled.div`
  display: flex;
  flex-flow: column;
`

const SecondaryLinks = styled.div`
  display: flex;
  flex-flow: column;
  gap: ${({ theme }) => theme.spacer}px;
`

interface SliderProps {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

export const Slider = ({ isOpen, setIsOpen }: SliderProps): JSX.Element => {
  const { primaryLinks, secondaryLinks } = useHeaderContent()

  useEffect(() => {
    if (isOpen) {
      document.documentElement.style.overflowY = "hidden"
      return
    }
    document.documentElement.style.overflowY = "scroll"
  }, [isOpen])

  const handleClick = (): void => {
    setIsOpen(false)
  }

  return (
    <Wrapper isOpen={isOpen}>
      <TopBar>
        <Link to="/">
          <StyledLogo />
        </Link>
        <CloseButton onClick={handleClick}>
          <Icon name="xMark" />
        </CloseButton>
      </TopBar>
      <nav>
        <PrimaryLinks>
          {primaryLinks.map((link) => (
            <Link key={link.id} to={link.url}>
              <StyledTitle1 as="h2">{link.text}</StyledTitle1>
            </Link>
          ))}
        </PrimaryLinks>
        <VerticalSpacing size="lg" />
        <SecondaryLinks>
          {secondaryLinks &&
            secondaryLinks.map((link) => (
              <Link key={link.id} to={link.url}>
                <Paragraph>{link.text}</Paragraph>
              </Link>
            ))}
        </SecondaryLinks>
        <VerticalSpacing size="lg" />
      </nav>
    </Wrapper>
  )
}
