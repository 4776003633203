import { graphql, useStaticQuery } from "gatsby"
import { ContentfulLink } from "types/contentful"

export const useHeaderContent = (): ContentfulHeader | undefined => {
  const { allContentfulHeader } = useStaticQuery<Data>(graphql`
    query {
      allContentfulHeader {
        nodes {
          metadata {
            tags {
              contentful_id
            }
          }
          primaryLinks {
            id
            text
            url
          }
          secondaryLinks {
            id
            text
            url
          }
          theme
        }
      }
    }
  `)

  const header = allContentfulHeader.nodes.find((aHeader: ContentfulHeader) =>
    aHeader.metadata.tags.find((tag) => tag.contentful_id === process.env.GATSBY_SITE_CHANNEL),
  )

  return header
}

interface Data {
  allContentfulHeader: {
    nodes: ContentfulHeader[]
  }
}

interface ContentfulHeader {
  primaryLinks: ContentfulLink[]
  secondaryLinks: ContentfulLink[]
  theme: "light" | "dark"
  metadata: {
    tags: [
      {
        contentful_id: string
      },
    ]
  }
}
