import { useMetaImage } from "components/fragments/Seo/hooks/useMetaImage"
import { IGatsbyImageData, getSrc } from "gatsby-plugin-image"
import { useSiteMetadata } from "hooks/useSiteMetadata"

import { Helmet } from "react-helmet"
import { pushPageChange, pushUserProperties } from "utils/tracking"

interface SeoProps {
  description?: string
  image?: IGatsbyImageData | undefined
  pathname: string
  title: string
  type?: string
  is404?: boolean
  noIndexNoFollow?: boolean
}

export const Seo = ({
  description,
  image,
  pathname,
  title,
  type,
  is404,
  noIndexNoFollow = false,
}: SeoProps): JSX.Element => {
  const { siteUrl, social } = useSiteMetadata()
  const { metaImage: defaultMetaImage } = useMetaImage()

  const metaDescription =
    description ||
    "At Einride, our mission is to design and develop intelligent technologies for movement. We offer a more cost effective freight transport system with zero emissions, zero waste and zero traffic deaths."
  const metaImage = image ? getSrc(image) : getSrc(defaultMetaImage)
  const metaTitle = title || "Einride, intelligent technologies for movement"
  const metaType = type || "website"
  const metaUrl = `${siteUrl}${pathname}`
  const lang = "en"

  if (typeof window !== "undefined") {
    pushUserProperties()
    pushPageChange(metaTitle, is404 ? "404_page" : "virtual_page_view")
    if (!window.sessionStorage.getItem("currentPage")) {
      window.sessionStorage.setItem("currentPage", window.location.pathname)
    }
  }

  const metaTags: React.DetailedHTMLProps<
    React.MetaHTMLAttributes<HTMLMetaElement>,
    HTMLMetaElement
  >[] = [
    {
      name: "description",
      content: metaDescription,
    },
    {
      name: "image",
      content: metaImage,
    },
    {
      name: "og:url",
      content: metaUrl,
    },
    {
      property: "og:title",
      content: metaTitle,
    },
    {
      property: "og:description",
      content: metaDescription,
    },
    {
      property: "og:type",
      content: metaType,
    },
    {
      property: "og:image",
      content: metaImage,
    },
    {
      name: "twitter:card",
      content: "summary_large_image",
    },
    {
      name: "twitter:creator",
      content: social.twitter,
    },
    {
      name: "twitter:title",
      content: metaTitle,
    },
    {
      name: "twitter:description",
      content: metaDescription,
    },
    {
      name: "twitter:image",
      content: metaImage,
    },
  ]

  if (noIndexNoFollow) {
    metaTags.push({
      name: "robots",
      content: "noindex,nofollow",
    })
  }

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={metaTitle}
      titleTemplate="%s | Einride"
      meta={metaTags}
    />
  )
}
