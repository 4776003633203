import styled from "@emotion/styled"
import { Link as GatsbyLink } from "gatsby"
import { useSiteMetadata } from "hooks/useSiteMetadata"
import { CSSProperties, PropsWithChildren, ReactNode, isValidElement } from "react"
import { EventType, handleLinkOnClick } from "utils/tracking"

const StyledInternalLink = styled(GatsbyLink)``
const StyledExternalLink = styled.a``

interface InternalLink {
  to: string
  activeStyle?: CSSProperties
  onClick?: () => void
  partiallyActive?: boolean
  shortenLink?: boolean
  state?: {
    fromCategory:
      | {
          id: string
          name: string
        }
      | undefined
  }
}

type LinkProps = { className?: string; linkType?: EventType } & InternalLink

export const Link = ({
  children,
  className,
  linkType,
  onClick,
  shortenLink = true,
  to,
  ...props
}: PropsWithChildren<LinkProps>): JSX.Element => {
  const { siteUrl } = useSiteMetadata()
  const { activeStyle, partiallyActive, state } = props

  const hasChildren = (element: ReactNode): boolean => {
    return isValidElement(element) && Boolean(element.props.children)
  }

  const childrenToText = (elChildren: string | ReactNode): string | ReactNode => {
    if (isValidElement(elChildren) && hasChildren(elChildren)) {
      return childrenToText(elChildren.props.children)
    }
    if (Array.isArray(elChildren)) {
      return elChildren.map((elChild) => childrenToText(elChild.props.children)).join(" ")
    }
    return elChildren
  }

  const handleChildrenToText = (elChildren: string | ReactNode): string => {
    return childrenToText(elChildren) as string
  }

  if (!to) {
    return <>{children}</>
  }

  if (to.startsWith(siteUrl) && shortenLink) {
    // `to` is internal link in form https://www.einride.tech/to
    const relativeTo = to.split(siteUrl)[1]
    return (
      <StyledInternalLink
        activeStyle={activeStyle ?? {}}
        state={state ?? {}}
        to={relativeTo}
        className={className}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onClick={() =>
          handleLinkOnClick(
            handleChildrenToText(children),
            relativeTo,
            linkType ?? "internal_link",
            onClick,
          )
        }
        partiallyActive={partiallyActive ?? false}
      >
        <>{children}</>
      </StyledInternalLink>
    )
  }
  if (!to.startsWith("http")) {
    // `to` is internal link in form /to
    const absoluteTo = to.startsWith("/") ? to : `/${to}`
    return (
      <StyledInternalLink
        activeStyle={activeStyle ?? {}}
        to={absoluteTo}
        className={className}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onClick={() =>
          handleLinkOnClick(
            handleChildrenToText(children),
            absoluteTo,
            linkType ?? "internal_link",
            onClick,
          )
        }
        partiallyActive={partiallyActive ?? false}
      >
        <>{children}</>
      </StyledInternalLink>
    )
  }
  return (
    // `to` is external link
    <StyledExternalLink
      href={to}
      className={className}
      target="_blank"
      rel="noopener noreferrer nofollow"
      onClick={() =>
        handleLinkOnClick(handleChildrenToText(children), to, linkType ?? "external_link")
      }
    >
      <>{children}</>
    </StyledExternalLink>
  )
}
