import { Theme } from "@einride/ui"
import { useTheme } from "@emotion/react"
import styled from "@emotion/styled"
import { Link } from "components/ui/Link/Link"
import { Paragraph } from "lib/ui/Typography/Typography"
import { ReactNode } from "react"

const StyledParagraph = styled(Paragraph)`
  margin: 0;
  color: ${({ theme }) => theme.colors.content.secondary};
`

const getActiveStyle = (theme: Theme): { color: string; borderBottom: string } => ({
  color: theme.colors.content.primary,
  borderBottom: `1px solid ${theme.colors.border.selected}`,
})

interface NavLinkProps {
  children: ReactNode
  onClick?: () => void
  to: string
}

export const NavLink = ({ children, onClick, to }: NavLinkProps): JSX.Element => {
  const theme = useTheme()

  return (
    <StyledParagraph>
      <Link
        to={to}
        activeStyle={getActiveStyle(theme)}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onClick={onClick ?? (() => {})}
        partiallyActive
      >
        {children}
      </Link>
    </StyledParagraph>
  )
}
