import {
  Caption as DefaultCaption,
  MegaTitle as DefaultMegaTitle,
  Paragraph as DefaultParagraph,
  Title1 as DefaultTitle1,
  Title2 as DefaultTitle2,
  Title3 as DefaultTitle3,
} from "@einride/ui"
import styled from "@emotion/styled"
import { mediaQueries } from "lib/theming/breakpoint"

export const MegaTitle = styled(DefaultMegaTitle)`
  @media ${mediaQueries.belowMd} {
    font-size: ${({ theme }) => theme.fontSizes["2xl"]};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    line-height: calc(6 / 5);
    margin-top: 1px;
    margin-bottom: 7px;
  }
`

export const Title1 = styled(DefaultTitle1)`
  @media ${mediaQueries.belowMd} {
    font-size: ${({ theme }) => theme.fontSizes.xl};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    line-height: calc(8 / 7);
    margin-top: 6px;
    margin-bottom: 2px;
  }
`

export const Title2 = styled(DefaultTitle2)`
  @media ${mediaQueries.belowMd} {
    font-size: ${({ theme }) => theme.fontSizes.lg};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    line-height: calc(4 / 3);
    margin-top: 7px;
    margin-bottom: 1px;
  }
`

export const Title3 = styled(DefaultTitle3)`
  @media ${mediaQueries.belowMd} {
    font-size: 1.25rem;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    line-height: calc(4 / 3);
    margin-top: 15px;
    margin-bottom: 3px;
  }
`

export const Paragraph = styled(DefaultParagraph)``

export const Caption = styled(DefaultCaption)``
