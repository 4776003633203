import styled from "@emotion/styled"
import { cvars, mediaQueries } from "lib/theming/breakpoint"

export const ContentWrapper = styled.div`
  width: 100%;
  padding: 0 ${cvars.sectionFrameSpacing};
`

type SectionProps = {
  background?: string
}

export const Section = styled.section<SectionProps>`
  display: flex;
  flex-direction: column;
  padding-bottom: 180px;
  background: ${(props) => (props.background ? props.background : "inherit")};
  @media screen and (max-width: 767px) {
    padding-bottom: 120px;
  }
`

export const Desktop = styled.div`
  display: none;

  @media ${mediaQueries.md} {
    display: unset;
  }
`

export const Mobile = styled.div`
  @media ${mediaQueries.md} {
    display: none;
  }
`

export const HideBelow = styled.div<{ breakpoint: number }>`
  @media screen and (max-width: ${(props) => props.breakpoint}px) {
    display: none;
  }
`

export const HideAbove = styled.div<{ breakpoint: number }>`
  @media screen and (min-width: ${(props) => props.breakpoint + 1}px) {
    display: none;
  }
`

interface RowProps {
  breakpoint?: number
  noWrap?: boolean
}

export const Row = styled.div<RowProps>`
  display: flex;
  > div:not(:last-of-type) {
    padding-right: 5px;
  }
  > div:not(:first-of-type) {
    padding-left: 5px;
  }

  @media screen and (max-width: ${(props) => (props.breakpoint ? props.breakpoint : 1200 - 1)}px) {
    > div:not(:last-of-type) {
      padding-right: 10px;
    }
    > div:not(:first-of-type) {
      padding-left: 10px;
    }
  }

  @media screen and (max-width: ${(props) => (props.breakpoint ? props.breakpoint : 768 - 1)}px) {
    flex-wrap: ${(props) => (props.noWrap ? "unset" : "wrap")};
    > div:not(:last-of-type) {
      padding-right: 0;
    }
    > div:not(:first-of-type) {
      padding-left: 0;
    }
  }
`

type ColProps = {
  width: number
  mobileWidth?: number
  breakpoint?: number
}

export const Col = styled.div<ColProps>`
  width: ${(props) => props.width * 100}%;

  @media screen and (max-width: ${(props) => (props.breakpoint ? props.breakpoint : 768 - 1)}px) {
    width: ${(props) => (props.mobileWidth ? props.mobileWidth * 100 : 100)}%;
  }
`

export const HeadingWrapper = styled.div`
  margin-bottom: 30px;
  @media (max-width: 576px) {
    margin-bottom: 20px;
  }
`

export const SmallHeadingWrapper = styled.div`
  margin-bottom: 20px;
  @media (max-width: 576px) {
    margin-bottom: 10px;
  }
`

export const ImageWrapper = styled.div`
  margin-bottom: 20px;
`

export const InsightCardsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${cvars.gridGutter};

  @media ${({ theme }) => theme.mediaQueries.md} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${({ theme }) => theme.mediaQueries.lg} {
    grid-template-columns: repeat(4, 1fr);
  }
`
