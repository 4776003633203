import { LIB_VERSION } from "version"

export type EventType =
  | "lead_form_submission_failed"
  | "begin_lead_form"
  | "submit_lead_form"
  | "user_properties"
  | "article_page_view"
  | "document_download_failed"
  | "document_download_success"
  | "internal_link"
  | "external_link"
interface PageDataType {
  page_path: string
  previous_page_path?: string | undefined
  title: string
}

interface FormType {
  country?: string | undefined
  company: string
  email?: string | undefined
  full_name?: string | undefined
  company_size?: string | undefined
  topic?: string | undefined
  phone?: string | undefined
}

interface LeadDetailsType extends FormType {
  formType: string
}

interface UserPropertiesType {
  country: string | undefined
  device: string
  environment?: string | undefined
  version: string
}

interface EventDataType {
  text: string
  destination: string
}

interface ContentDetailsType {
  article_headline: string | undefined
  article_category: string[] | undefined
  article_author: string | undefined
  pageData: PageDataType | undefined
}

interface CarouselDataType {
  carouselName: string
  currentIndex: number
}

interface DataLayerType {
  userData?: UserPropertiesType | undefined
  event?: EventType
  documentDownloadDetails?: string | undefined
  leadDetails?: LeadDetailsType | undefined
  pageData?: PageDataType | undefined
  eventData?: EventDataType | undefined
  contentDetails?: ContentDetailsType | undefined
  carouselData?: CarouselDataType | undefined
}

export const pushToDatalayer = (data: DataLayerType): void => {
  if (typeof window !== "undefined" && window.dataLayer) {
    const dataWithSite = {
      ...data,
      subdomain: process.env.GATSBY_SITE_CHANNEL?.replace("channel", "").toLowerCase(),
      page_url: window.location.href,
    }
    window.dataLayer.push(dataWithSite)
  }
}

const userPropertiesIsSet = (dataLayer: DataLayerType[]): boolean => {
  return Boolean(
    dataLayer.filter((dataLayerItem) => dataLayerItem.event === "user_properties").length,
  )
}

const getDeviceType = (): "tablet" | "mobile" | "desktop" => {
  const ua = navigator.userAgent
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet"
  }
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua,
    )
  ) {
    return "mobile"
  }
  return "desktop"
}

export const getLatestPageData = (): PageDataType | undefined => {
  if (typeof window !== "undefined" && window.dataLayer) {
    const virtualPageViewCollection = window.dataLayer.filter(
      (dataLayerItem) => dataLayerItem.event === "virtual_page_view",
    )
    if (virtualPageViewCollection && virtualPageViewCollection.length > 0) {
      if (virtualPageViewCollection[virtualPageViewCollection.length - 1])
        return virtualPageViewCollection[virtualPageViewCollection.length - 1].pageData
      return undefined
    }
    return undefined
  }
  return undefined
}

const toSnakeCase = (text: string): string => {
  if (text !== null) {
    return text
      .replace(/\W+/g, " ")
      .split(/ |\B(?=[A-Z])/)
      .map((word) => word.toLowerCase())
      .join("_")
  }
  return ""
}

export const pushLeadFormData = (event: EventType, form: FormType, leadSource: string): void => {
  const leadDetails = {
    topic: form.topic ?? undefined,
    country: form.country ?? undefined,
    company: form.company ?? undefined,
    company_size: form.company_size ?? undefined,
    formType: toSnakeCase(leadSource),
  }

  if (event)
    pushToDatalayer({
      event,
      leadDetails,
      pageData: getLatestPageData(),
    })
}

export const pushVideoData = (event: EventType): void => {
  pushToDatalayer({
    event,
    pageData: getLatestPageData(),
  })
}

export const pushDownloadData = (event: EventType, documentUrl?: string): void => {
  pushToDatalayer({
    event,
    documentDownloadDetails: documentUrl,
    pageData: getLatestPageData(),
  })
}

export const pushCarouselData = (
  event: EventType,
  carouselName: string,
  currentIndex: number,
): void => {
  pushToDatalayer({
    event,
    carouselData: {
      carouselName,
      currentIndex,
    },
    pageData: getLatestPageData(),
  })
}

const pushLinkData = async (
  linkText: string,
  destination: string,
  linkType: EventType,
): Promise<void> => {
  const pageData = await getLatestPageData()

  pushToDatalayer({
    event: linkType,
    eventData: {
      text: linkText,
      destination,
    },
    pageData,
  })
}

export const pushUserProperties = (): void => {
  if (window.dataLayer && !userPropertiesIsSet(window.dataLayer)) {
    pushToDatalayer({
      event: "user_properties",
      userData: {
        country: undefined,
        device: getDeviceType() ?? undefined,
        environment: process.env.NODE_ENV ?? undefined,
        version: LIB_VERSION,
      },
    })
  }
}

export const pushPageChange = (pageTitle: string, eventType: EventType): void => {
  pushToDatalayer({
    event: eventType,
    pageData: {
      page_path: window.location.pathname,
      previous_page_path: window.sessionStorage.getItem("currentPage") ?? undefined,
      title: pageTitle,
    },
  })
  window.sessionStorage.setItem("currentPage", window.location.pathname)
}

export const handleLinkOnClick = (
  linkText: string,
  destination: string,
  linkType: EventType,
  onClick?: (() => void) | undefined,
): void => {
  if (typeof window !== "undefined") {
    pushLinkData(linkText, destination, linkType)
  }
  if (onClick) onClick()
}
