import { graphql, useStaticQuery } from "gatsby"
import { IGatsbyImageData } from "gatsby-plugin-image"

interface Data {
  socialMediaImageMain: {
    media: {
      gatsbyImageData: IGatsbyImageData
    }
  }
  socialMediaImageMesh: {
    media: {
      gatsbyImageData: IGatsbyImageData
    }
  }
}

export const useMetaImage = (): { metaImage: IGatsbyImageData } => {
  const { socialMediaImageMain, socialMediaImageMesh } = useStaticQuery<Data>(
    graphql`
      query {
        socialMediaImageMain: contentfulImage(contentful_id: { eq: "1MRDUlWwP4dNehEqSUtnKf" }) {
          media {
            gatsbyImageData(height: 627)
          }
        }
        socialMediaImageMesh: contentfulImage(contentful_id: { eq: "7xbWKnQ5AD1uP1TEl6ZKcL" }) {
          media {
            gatsbyImageData(height: 627)
          }
        }
      }
    `,
  )

  return {
    metaImage:
      process.env.GATSBY_SITE_CHANNEL === "channelMain"
        ? socialMediaImageMain.media.gatsbyImageData
        : socialMediaImageMesh.media.gatsbyImageData,
  }
}
